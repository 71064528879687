<template>
  <section class="section-services">
    <services-banner :headerHeight="295" :detailPage="true" />
    <template>
      <section class="pa-lg-16 py-sm-16">
        <v-container fluid>
          <v-row justify="center">
            <h1
              class="text-center text-lg-h3 text-h5 font-italic mb-lg-10 px-10 py-8 pt-sm-4 pb-sm-10 px-sm-16"
              v-html="$t('services.ourServices')"
            ></h1>
          </v-row>
          <v-row justify="center" class="pt-lg-16">
            <v-col
              v-for="(service) in servicesData"
              :key="service.id"
              cols="12"
              xs="12"
              lg="4"
              class="pa-0"
            >
              <template>
                <v-card
                class="my-lg-12 my-10 shadow rounded-b-lg rounded-t-0 card-service"
                >
                <!-- ^^^^:class="service.class"^^^^ -->
                  <div class="text-center pr-4 card-service-icon">
                    <v-card
                      class="rounded-circle ml-auto d-flex align-center"
                      height="100"
                      width="100"
                      :elevation="0"
                    >
                      <v-img
                        :src="service.image_url"
                        contain
                        width="55"
                        height="50"
                        class="mx-auto"
                      ></v-img>
                    </v-card>
                  </div>
                  <v-card-title
                    class="font-weight-bold title-service pt-14 pl-8"
                  >
                    {{ $t(service.title[locale]) }}
                  </v-card-title>

                  <v-card-text class="text-subtitle-2 font-weigth-light pl-8">
                    <p v-html="service.descripcion[locale] || ''">
                    </p>
                    <ul class="font-weight-bold pa-0">
                      <li v-for="(item) in service.tag"
                       :key="item.id">
                        {{ $t(item.name[locale]) }}
                      </li>
                    </ul>
                  </v-card-text>
                </v-card>
              </template>
            </v-col>
          </v-row>
          <v-row justify="center" class="py-lg-10 pb-10 ">
            <v-btn
              elevation="0"
              class="rounded-lg"
              :style="{ background: $routeMeta.meta.active.color }"
              width="367"
              :href="$t('services.formRequestInfo')"
              target="_blank"
            >
              <span
                class="font-weight-bold text-white text-body-2 btn btn-request-info"
              >
                {{ $t("services.btnRequestInfo") }}
              </span>
            </v-btn>
          </v-row>
        </v-container>
      </section>
      <section class="py-sm-16 odd">
        <v-container fluid class="py-lg-16 px-sm-16 px-lg-0">
          <v-row>
            <v-col>
              <h1
                class="text-center text-lg-h3 text-h5 font-weight-bold py-8 pt-sm-0 pb-sm-14 "
                :style="{ color: $routeMeta.meta.active.color }"
              >
                {{ $t("services.technologyTitle") }}
              </h1>
            </v-col>
          </v-row>

          <v-row justify="center" class="mt-0 mb-9">
            <v-col cols="12" xs="12" lg="9" class="py-0">
              <p
                class="text-center py-lg-5 px-lg-5 px-10 text-lg-body2 text-subtitle-1  font-weight-light about-technology"
              >
                {{ $t("services.technologyDescription") }}
              </p>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col
              cols="6"
              lg="2"
              xs="6"
              sm="4"
              class="my-5"
              v-for="item in technologyData"
              :key="item.image_url"
            >
              <v-card
                class="used-technology mx-auto"
                :class="[item.id % 2 === 0 ? 'up' : 'down']"
                height="120"
                width="120"
                :elevation="0"
              >
                <v-img
                  :lazy-src="item.image_url"
                  height="100%"
                  class="rounded-circle"
                ></v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section>
        <v-container fluid class="pa-0">
          <v-img
            :eager="true"
            lazy-src="/servicios.svg"
            src="/banners/banner_service_blue.svg"
            :max-height="500"
            :min-height="500"
            class="headImage d-flex align-center"
            v-ripple
          >
            <v-container class="py-lg-16 py-10 px-5">
              <v-row class="justify-lg-start justify-center">
                <p
                  class="text-white text-lg-h3 text-h5 font-weight-light font-italic pt-lg-8 pb-6 text-center text-lg-left px-12 px-lg-0"
                  v-html="$t('services.hostingTitle')"
                ></p>
              </v-row>
              <v-row class="justify-lg-start justify-center">
                <p
                  class="text-white font-weight-light text-lg-h5 text-subtitle-1 text-center px-12 px-lg-0 text-lg-left"
                  v-html="$t('services.hostingDescription')"
                ></p>
              </v-row>
              <v-row class="pt-lg-16 pt-8 justify-lg-start justify-center">
                <a
                  href="https://mixarthost.com/"
                  target="_blank"
                  style="text-decoration: none"
                >
                  <v-btn elevation="1" class="rounded-lg">
                    <span
                      :style="{ color: $routeMeta.meta.active.color }"
                      class="font-weight-bold text-body-2 btn"
                    >
                      {{ $t("services.btnKnowMore") }}
                    </span>
                  </v-btn>
                </a>
              </v-row>
            </v-container>
          </v-img>
        </v-container>
      </section>
      <section class="py-sm-16 py-lg-10 section-creativity">
        <v-container fluid class="py-lg-10">
          <v-row>
            <v-col>
              <h1
                class="text-center text-lg-h3 text-h5 font-italic pt-8 pt-sm-0 mb-lg-10 mb-5 mb-sm-10 px-10 px-lg-0"
                v-html="$t('services.ourLatestProjectsTitle')"
              ></h1>
            </v-col>
          </v-row>
          <v-row class="px-16 mt-0">
            <v-col
              cols="12"
              xs="10"
              sm="6"
              lg="4"
              class="pa-0 projects-card"
              v-for="project in projectData"
              :key="project.id"
            >
              <template
              v-if="project.favorite">
                <v-card
                  class="mx-auto my-lg-12 my-8 rounded-lg elevation-1"
                  max-width="374"
                  height="370"
                >
                  <v-img
                    height="250"
                    :src="project.image_url"
                    class="image-page elevation-0"
                  >
                    <v-container fluid fill-height class="page ma-0 pa-0">
                      <v-row justify="center">
                        <a
                          :href="project.url"
                          target="_blank"
                          style="text-decoration: none"
                        >
                          <v-btn elevation="0" class="rounded-lg" width="110">
                            <span class="font-weight-bold btn btn-projects">
                              {{ $t("services.btnShow") }}</span
                            >
                          </v-btn>
                        </a>
                      </v-row>
                    </v-container>
                  </v-img>

                  <v-card-title class="font-weight-bold project-name">{{
                    project.name
                  }}</v-card-title>

                  <v-card-text>
                    <div class="my-n4 font-weigth-light text-body-2">
                      <div v-html="project.details"></div>
                    </div>
                  </v-card-text>
                  <v-card-text>
                    <v-chip-group
                      active-class="white--text"
                      column
                      class="mt-n2"
                    >
                      <v-chip
                        v-for="technology in project.technology"
                        :key="technology.id"
                        class="name-tecnologia"
                        :color="technology.color"
                        :text-color="technology.textColor"
                        ><span
                          >{{ technology.title }}</span
                        ></v-chip
                      >
                    </v-chip-group>
                  </v-card-text>
                </v-card>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section class="py-sm-16 py-lg-10 odd">
        <v-container fluid class="px-10 py-lg-16">
          <v-row class="px-lg-16" justify="center">
            <h1
              class="text-center text-lg-h3 text-h5 py-8 pt-sm-6 font-weight-bold"
              :style="{ color: $routeMeta.meta.active.color }"
            >
              {{ $t("services.strategicAlly") }}
            </h1>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" lg="7" xs="12">
              <p
                class="pt-lg-7 text-dark font-weight-light text-center text-lg-justify text-lg-h5 text-subtitle-1"
              >
                {{ $t("services.strategicAllyDescription") }}
              </p>
            </v-col>
          </v-row>
          <v-row justify="center" class="my-8">
            <v-btn
              elevation="1"
              class="rounded-lg"
              to="/contact-us/overview"
              :style="{ background: $routeMeta.meta.active.color }"
            >
              <span class="font-weight-bold text-white btn">
                {{ $t("services.btnContactUs") }}</span
              >
            </v-btn>
          </v-row>
        </v-container>
      </section>
      <section>
        <v-container fluid class="pa-0 mb-lg-n10">
          <v-img
            src="/banners/banner_service_dark.svg"
            :min-height="500"
            :max-height="500"
            class="headImage d-flex align-center"
            v-ripple
          >
            <v-container class="pa-lg-16 py-lg-5 px-10 px-sm-16">
              <v-row class="justify-lg-end justify-center">
                <p
                  class="text-white text-lg-h3 text-h5 font-weight-light font-italic pt-8 text-uppercase text-center mb-0 "
                >
                  {{ $t("services.strategicAlliesTitle") }}
                </p>
              </v-row>
              <v-row
                class="md_logo justify-lg-end justify-center mt-0 py-lg-6 py-sm-5"
              >
                <v-col cols="6" xl="4" class="pa-0">
                  <v-img height="100" src="/logos/mdGroup.svg" contain></v-img>
                </v-col>
              </v-row>
              <v-row class="justify-lg-end justify-center mt-0">
                <p
                  class="text-white font-weight-light text-lg-right text-lg-h5 text-subtitle-1 text-center"
                  v-html="$t('services.alliesDescription')"
                ></p>
              </v-row>
            </v-container>
          </v-img>
        </v-container>
      </section>
    </template>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import serviceProjects from "../services/projects";
import serviceServices from "../services/services";
import serviceTechnologies from "../services/technologies";
export default {
  name: "Services",
  metaInfo() {
    return {
      title: this.$t("services.servicesDescription"),
      meta: [
        {
          name: "description",
          content: this.$t("services.developmentDescription")
        },
        {
          name: "description",
          content: this.$t("services.designDescription")
        },
        {
          name: "description",
          content: this.$t("services.asesoryDescription")
        },
        {
          name: "description",
          content: this.$t("services.technologyDescription")
        },
        {
          name: "description",
          content: this.$t("services.hostingDescription")
        },
        {
          name: "description",
          content: this.$t("services.strategicAllyDescription")
        }
      ]
    };
  },
  data: () => ({
    projectData:[],
    servicesData: [],
    technologyData: [],
    locale: 'es',
    items: [
      {
        id: 1,
        name: "Node.JS",
        src: "/technologies/nodejs.svg"
      },
      {
        id: 2,
        name: "AWS",
        src: "/technologies/amazon.svg"
      },
      {
        id: 3,
        name: "Laravel",
        src: "/technologies/laravel.svg"
      },
      {
        id: 4,
        name: "PHP",
        src: "/technologies/php.svg"
      },
      {
        id: 5,
        name: "MySQL",
        src: "/technologies/mysql.svg"
      },
      {
        id: 6,
        name: "JavaScript",
        src: "/technologies/js.svg"
      },
      {
        id: 7,
        name: "WordPress",
        src: "/technologies/wordpress.png"
      },
      {
        id: 8,
        name: "Vue.js",
        src: "/technologies/vuejs.png"
      },

      {
        id: 9,
        name: "shopify",
        src: "/technologies/shopify.png"
      },
      {
        id: 10,
        name: "flutter",
        src: "/technologies/flutter.png"
      }
    ],
    projects: [
      {
        src: "/projects/Dazi.png",
        name: "Dazi Solar",
        description: "Landing Page",
        technologies: ["WordPress", "JavaScript"],
        href: "https://dazisolar.com/",
        class: "ml-lg-auto mr-lg-0 mx-sm-5"
      },
      {
        src: "/projects/legalcrew.png",
        name: "Legal Crew",
        description: "Landing Page",
        technologies: ["WordPress", "JavaScript"],
        href: "https://legalcrew.do/",
        class: "mx-sm-5 mx-lg-auto"
      },
      {
        src: "/projects/Gingerly.png",
        name: "Gingerly",
        description: "E-commerce",
        technologies: ["Shopify", "JavaScript"],
        href: "https://gingerlyshop.com/",
        class: "mr-lg-auto ml-lg-0 mx-sm-5"
      }
    ],
    services: [
      {
        src: "/logos/develop_icon.svg",
        class: "ml-lg-auto mr-lg-0 mx-auto",
        title: "services.developmentTitle",
        description: "services.developmentDescription",
        items: ["services.webDevelopment", "services.appDevelopment"]
      },
      {
        src: "/logos/design_icon.svg",
        class: "mx-lg-auto mx-auto",
        title: "services.designTitle",
        description: "services.designDescription",
        items: [
          "services.webDesign",
          "services.imageCorpDesign",
          "services.contentDesign"
        ]
      },
      {
        src: "/logos/consulting_icon.svg",
        class: "mr-lg-auto ml-lg-0 mx-auto",
        title: "services.asesoryTitle",
        description: "services.asesoryDescription",
        items: [
          "services.asesoryTechnology",
          "services.seoAsesory",
          "services.analysisDataAsesory"
        ]
      }
    ]
  }),
  created() {
    const country = localStorage.getItem("country");
    if (country) {
      const parsedLocale = JSON.parse(country)
      this.locale = parsedLocale.locale
    }
  },
  computed: {
    ...mapGetters(["getTechnology"]),
  },
  mounted: async function() {

    const resultTech = await serviceTechnologies.getTechnologies();
    this.technologyData = resultTech.data;

    const resultProjects = await serviceProjects.getProjects();
    this.projectData = resultProjects.data;

    const result = await serviceServices.getServices();
    this.servicesData = result.data;
    
  }
};
</script>
